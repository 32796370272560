var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": "90%"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    on: {
      "scroll": _vm.checkScroll
    }
  }, [_c('div', {
    staticClass: "pop-log-item hd"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._v("Time (UTC)")]), _c('div', {
    staticClass: "row"
  }, [_vm._v("Airdrop-Pass")])]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "row"
    }, [_vm._v(_vm._s(item.created_at.replace("T", " ").replace(".000Z", "")))]), _c('div', {
      staticClass: "row"
    }, [_vm._v(_vm._s(_vm._f("toThousands")(item.amount)))])]);
  }), _c('div', {
    staticClass: "pop-log-bottom"
  }, [_vm._v(" Invitation Rewards: " + _vm._s(_vm.airdropTicketInfo.totalInvitationAmount) + " ")]), _vm.isLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e()], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };