import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const airdropVuex = createNamespacedHelpers("airdrop");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "airdropTap",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...airdropVuex.mapState(["airdropTicketInfo"]),
    currentFormattedDate() {
      const now = new Date();
      // 你可以根据需求调整日期格式  
      // 这里使用`YYYY-MM-DD`格式  
      // 注意：getFullYear()等函数不区分大小写  
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以要+1  
      const day = String(now.getDate()).padStart(2, '0');
      return Number(`${year}${month}${day}`);
    }
  },
  data() {
    return {
      progressList: [{
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }, {
        progress: 0,
        isOk: false
      }],
      exchangesList: [{
        name: 'Bitget',
        progress: 75,
        icon: "bitget.jpg"
      }, {
        name: 'Gate',
        progress: 75,
        icon: "gate.jpg"
      }, {
        name: 'LBank',
        progress: 73,
        icon: "lbank.jpg"
      }, {
        name: 'ZBX',
        progress: 72,
        icon: "zbx.jpg"
      }, {
        name: 'MEXC',
        progress: 52,
        icon: "mexc.jpg"
      }, {
        name: 'OKX',
        progress: 40,
        icon: "okx.jpg"
      }],
      isProgOk: false,
      countDown: 0,
      dateInfos: [{
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }, {
        date: "",
        claimed: false
      }],
      isFlag: true,
      popAirdropTicketTipShow: false,
      popAirdropTicketLogsShow: false,
      popAirdropTicketDescShow: false,
      tipData: {
        icon: "",
        title: "",
        brief: "",
        btns: []
      },
      countDownTime: null,
      selectTab: 0
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    this.isProgOk = false;
    this.popAirdropTapTipShow = false;
    this.popAirdropTapLogsShow = false;
    this.clearIntervalFn();
    clearTimeout(this.countDownTime);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "isProgOk": {
      handler() {
        if (this.isProgOk) {
          this.formatDateInfos();
        }
      }
    },
    "airdropTicketInfo": {
      handler() {
        this.doData();
      }
    }
  },
  async mounted() {
    await this.getAirdropTickeInfo();
    this.doData();
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    ...airdropVuex.mapMutations(["setAirdropTicketInfo"]),
    ...airdropVuex.mapActions(["getAirdropTickeInfo"]),
    doData() {
      this.countDown = this.airdropTicketInfo.countdown || 0;
      this.countDownLeft();
      if (this.airdropTicketInfo.enabled && !this.airdropTicketInfo.unclaimedAmount || !this.airdropTicketInfo.enabled) {
        this.progressList.map(item => {
          item.isOk = true;
          item.progress = 100;
          return item;
        });
      }
      this.animationFn();
    },
    formatDateInfos() {
      if (this.airdropTicketInfo.dateInfos && this.airdropTicketInfo.dateInfos.length < 5) {
        let len = this.airdropTicketInfo.dateInfos.length;
        for (let i = 0; i < 5 - len; i++) {
          this.airdropTicketInfo.dateInfos.unshift({
            date: "",
            claimed: false
          });
        }
      }
      this.dateInfos.map((item, index) => {
        if (this.airdropTicketInfo && this.airdropTicketInfo.dateInfos[index]) {
          item = this.airdropTicketInfo.dateInfos[index];
          this.dateInfos[index] = this.airdropTicketInfo.dateInfos[index];
        }
        return item;
      });
      console.log('this.dateInfos', this.dateInfos, this.airdropTicketInfo.dateInfos);
    },
    clearIntervalFn() {
      //console.log("我将准备清除所有定时器");
      for (let i = 1; i < 30000; i++) {
        clearInterval(i);
      }
    },
    animationFn() {
      let progressTimer = null;
      const fn = (index, callback) => {
        if (this.progressList[index].progress + 2 <= 100) this.progressList[index].progress += 2;
        if (this.progressList[index].progress >= 100) {
          this.progressList[index].isOk = true;
          if (index === 5) {
            //this.clearIntervalFn();
            clearInterval(progressTimer);
            this.isProgOk = true;
          }
        }
        if (this.progressList[index].progress >= 100) {
          typeof callback == "function" && callback();
        }
      };
      let timer = setTimeout(() => {
        clearTimeout(timer);
      }, 10);
      progressTimer = setInterval(() => {
        fn(0, () => {
          fn(1, () => {
            fn(2, () => {
              fn(3, () => {
                fn(4, () => {
                  fn(5, () => {});
                });
              });
            });
          });
        });
      }, 10);
    },
    async onClaim() {
      if (!this.isFlag) return;
      this.$loading.show();
      this.isFlag = false;
      let rs = await this.$http.post("/airdrop/ticket/claim", {});
      this.$loading.hide();
      vailcode(rs, () => {
        this.setAirdropTicketInfo(rs.data.airdropInfo);
        this.countDown = rs.data.countdown;
        this.countDownLeft();
        this.formatDateInfos();
        this.isFlag = true;
      }, () => {
        this.isFlag = true;
      });
    },
    countDownLeft() {
      clearTimeout(this.countDownTime);
      if (this.countDown > 0) {
        this.countDownTime = setTimeout(() => {
          this.countDown = this.countDown - 1;
          console.log('this.countDown', this.countDown);
          this.countDownLeft();
        }, 1000);
      }
    },
    onTip(t) {
      console.log(t);
      switch (t) {
        case 0:
          this.tipData.icon = require(`@images/${this.theme}/scan-head.jpg`);
          this.tipData.title = 'Anti-bot verification';
          this.tipData.brief = 'Complete anti-bot verification to get more airdrop rewards';
          this.tipData.txt = this.userData.verified_face == 0 ? 'Unverified' : 'Verified';
          this.tipData.star = 0;
          this.tipData.btns = [{
            "name": "Verify",
            "route": '/bioMatrixScan',
            "is_gray": this.userData.verified_face == 0 ? false : true
          }];
          break;
        case 1:
          this.tipData.icon = require(`@images/${this.theme}/tron.png`);
          this.tipData.title = 'Make TON transaction';
          this.tipData.brief = 'Complete the [Make TON transaction] task to get more airdrop rewards';
          this.tipData.txt = this.airdropTicketInfo.keyPoints.value.paymentCount;
          this.tipData.star = 0;
          this.tipData.btns = [{
            "name": "GO",
            "route": '/earn'
          }];
          break;
        // case 2:
        //   this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon2.png`)
        //   this.tipData.title='Keys'
        //   this.tipData.brief='More keys,more airdrop rewards'
        //   this.tipData.txt = formatter.toThousands(this.airdropTicketInfo.keyPoints.value.stock??0,2)
        //   this.tipData.star = this.airdropTicketInfo.keyPoints.rankText.stock == 'High' ? 3 : (this.airdropTicketInfo.keyPoints.rankText.stock == 'Medium' ? 2 : 1);
        //   this.tipData.btns = [
        //     {
        //       "name":"Improve",
        //       "route":'/earn'
        //     }
        //   ]
        //   break;
        case 2:
          this.tipData.icon = require(`@images/${this.theme}/ton-stars.png`);
          this.tipData.title = 'Donate Starts';
          this.tipData.brief = 'Complete the [Donate Starts] task to get more airdrop rewards';
          this.tipData.txt = formatter.toThousands(this.airdropTicketInfo.keyPoints.value.starsPaymentCount ?? 0, 2);
          //this.tipData.star = this.airdropTicketInfo.keyPoints.rankText.stock == 'High' ? 3 : (this.airdropTicketInfo.keyPoints.rankText.stock == 'Medium' ? 2 : 1);
          this.tipData.btns = [{
            "name": "GO",
            "route": '/earn'
          }];
          break;
        case 3:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon3.png`);
          this.tipData.title = 'Friends';
          this.tipData.brief = 'More friends,more airdrop rewards';
          this.tipData.txt = formatter.toThousands(this.airdropTicketInfo.keyPoints.value.invitationCount ?? 0, 2);
          this.tipData.star = this.airdropTicketInfo.keyPoints.rankText.invitationCount == 'High' ? 3 : this.airdropTicketInfo.keyPoints.rankText.invitationCount == 'Medium' ? 2 : 1;
          this.tipData.btns = [{
            "name": "Friends",
            "route": '/frens'
          }];
          break;
        case 4:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tap-icon1.png`);
          this.tipData.title = 'Profit per hour';
          this.tipData.brief = 'More Profit per hour More airdrop rewards';
          this.tipData.txt = formatter.toThousands(this.airdropTicketInfo.keyPoints.value.pph ?? 0, 2);
          this.tipData.star = this.airdropTicketInfo.keyPoints.rankText.pph == 'High' ? 3 : this.airdropTicketInfo.keyPoints.rankText.pph == 'Medium' ? 2 : 1;
          this.tipData.btns = [{
            "name": "Improve",
            "route": '/mine'
          }];
          break;
        case 5:
          this.tipData.icon = require(`@images/${this.theme}/airdrop-tickets.png`);
          this.tipData.title = 'Ticket';
          this.tipData.brief = 'More tickets,more airdrop rewards';
          this.tipData.txt = formatter.toThousands(this.airdropTicketInfo.keyPoints.value.appTickets ?? 0, 2);
          this.tipData.star = this.airdropTicketInfo.keyPoints.rankText.appTickets == 'High' ? 3 : this.airdropTicketInfo.keyPoints.rankText.appTickets == 'Medium' ? 2 : 1;
          this.tipData.btns = [{
            "name": "Improve",
            "route": '/appcenter'
          }];
          break;
      }
      this.popAirdropTicketTipShow = true;
    },
    onDetails() {
      this.popAirdropTicketLogsShow = true;
    },
    showLevelTip() {
      this.popAirdropTicketDescShow = true;
    },
    getNextDate(dateStr) {
      const date = new Date();
      let day = date.getDate();
      date.setDate(day + 1);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    },
    setSelectTab(index) {
      this.selectTab = index;
    },
    doShareMore() {
      this.$router.push('/airdropInviter');
    }
  }
};